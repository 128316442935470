import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {SearchService} from '../search.service';
import {SearchContext} from '../shared/search-context';
import { SwirlService } from '../swirl.service'
import { Router, ActivatedRoute } from '@angular/router';
import { WebSocketService } from '../websockets.service';
import { AuthService } from '../../auth/auth.service';

const RESULT_MIXER_KEY = 'result_mixer';
const DEFAULT_MIXER = 'result_mixer:RelevancyConfidenceMixer';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.css']
})
export class BoxComponent implements OnInit {

  searchForm: FormGroup;
  searchContext: SearchContext = new SearchContext();
  sources = [
    {value: 'default', name: 'All sources', connector: 'default'}
  ];
  selectedSources = new FormControl(['default'])
  previousSelectedSources = ['default']

  prompts = [
    {value: 'default', name: 'Swirl Prompt'}
  ]
  selectedPrompt = new FormControl('default')
  previousSelectedPrompt = 'default'
  hasPrompts = false

  previousParams: { q: string, providers: string, rag: string, rag_timeout: string, prompt: string } = {
    q: '',
    providers: '',
    rag: '',
    rag_timeout: '',
    prompt: ''
  }

  checkedGenerateAiResponse: boolean = false
  isSearchButtonDisabled: boolean = false
  isRAGButtonDisabled: boolean = false

  searchButtonLabel: string = 'SEARCH'
  searchBarPlaceholder: string = 'What are you searching for today?'
  aiResponseLabel: string = 'Generate AI Response'
  prompts_exists: boolean;

  constructor(private fb: FormBuilder, private searchService: SearchService, private swirl: SwirlService, private router: Router, private route: ActivatedRoute, private authService: AuthService, private webSocketService: WebSocketService) {
    this.searchForm = this.fb.group({
      query: [''],
      checkedGenerateAiResponse: new FormControl({
        value: false, // Default value
        disabled: true, // Initialize as disabled
      }),
    });
    this.prompts_exists = authService.getPromptsStatus()

  }

  makeSearch(params: any) {
    const q = params['q'];
    if(q) {
      const rag = params['rag']
      const prompt = params['prompt']
      if(rag) {
        this.webSocketService.changeStatus(true, true, [], prompt)
        this.searchForm.get('checkedGenerateAiResponse')?.patchValue(true)
      }

      if(prompt){
        this.selectedPrompt.setValue(prompt)
      }

      const providers = params['providers'];
      let parsedProviders
      if(providers?.includes(',')) {
        parsedProviders = providers.split(',')
        this.selectedSources.setValue(parsedProviders.map((p: any) => parseInt(p, 10)))
      }
      else if(providers)
        this.selectedSources.setValue([parseInt(providers, 10)])
      else
        this.selectedSources.setValue(['default'])
      const sourceConnectorName = this.sources.filter(s => this.selectedSources.value.includes(s.value)).map(s => s?.connector) || []
      const allSourceConnectorNames = this.sources.map(s => s.connector)
      const isDefault = this.selectedSources.value.length === 1 && this.selectedSources.value?.[0] === 'default'
      const sourceConnectorNames = [...new Set(isDefault ? allSourceConnectorNames : sourceConnectorName)]
      const theMixer = this.searchContext.filters.get(RESULT_MIXER_KEY) || DEFAULT_MIXER;
      this.searchService.putFilterWithoutSearch(RESULT_MIXER_KEY, theMixer);
      this.searchService.changeQueryAndProviders(q as string, isDefault ? [] : this.selectedSources.value, sourceConnectorNames);
    }
    else {
      this.selectedSources.setValue(['default'])
      this.searchForm.get('query')?.patchValue('');
    }
    setTimeout(() => {
      this.previousParams = {
        q: q || '',
        providers: params['providers'] || '',
        rag: params['rag'] || '',
        rag_timeout: params['rag_timeout'] || '',
        prompt: params['prompt'] || ''
      }
    }, 100)
  }

  compareObjects(obj1: any, obj2: any) {
    for (let key in obj1) {
      if (key == 'rag' || key == 'prompt') {
        continue;
      }
      if ((obj1?.[key] || '') != (obj2?.[key] || '')) {
        return false;
      }
    }
    for (let key in obj2) {
      if (key == 'rag' || key == 'prompt') {
        continue;
      }
      if ((obj1?.[key] || '') != (obj2?.[key] || '')) {
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    this.swirl.getSources().subscribe(async response => {
      response.filter((source: any) => source.active).sort((a: any, b: any) => a.name.localeCompare(b.name)).forEach((source: any) => {
        this.sources.push({
          value: source.id,
          name: source.name,
          connector: source.connector
        })
      })
      if(this.prompts_exists){
        this.swirl.getPrompts().subscribe(response => {
          response.filter(
            (prompt: any) => prompt.tags.some((tag: any) => tag.includes('rag'))
          ).forEach((prompt: any) => {
            this.prompts.push({
              value: prompt.id.toString(),
              name: this.formatPromptName(prompt.name),
            })
          })

          if(this.prompts.length > 1) {
            this.hasPrompts = true
          }
        })
      }
      this.searchService.currentSearchContext.subscribe(searchContext => {
        this.searchContext = searchContext;
        this.searchForm.patchValue({'query': this.searchContext.query});
      });
      this.route.queryParamMap.subscribe(params => {
        const state = this.router.getCurrentNavigation()?.extras.state;
        if(state?.rerun) {
          const queryParams = {
            q: params.get('q'),
            providers: params.get('providers'),
            rag: params.get('rag'),
            rag_timeout: params.get('rag_timeout'),
            prompt: params.get('prompt')
          }
          this.previousParams = {
            q: params.get('q') || '',
            providers: params.get('providers') || '',
            rag: params.get('rag') || '',
            rag_timeout: params.get('rag_timeout') || '',
            prompt: params.get('prompt') || ''
          }
          this.makeSearch(queryParams)
          this.router.navigate(['/'], { queryParams });
        }
        else {
          const paramsObject = {
            q: params.get('q'),
            providers: params.get('providers'),
            rag: params.get('rag'),
            rag_timeout: params.get('rag_timeout'),
            prompt: params.get('prompt')
          }

          if(!this.compareObjects(paramsObject, this.previousParams))
            this.makeSearch(paramsObject)
        }
      });
      this.searchService.eraseEvent.subscribe((isErased) => {
        if(isErased) {
          this.router.navigate(['/']);
          this.searchService.changeEraseEvent(false)
          this.searchForm.get('checkedGenerateAiResponse')?.patchValue(false)
          this.selectedPrompt.setValue('default')
          this.isSearchButtonDisabled = false
          this.isRAGButtonDisabled = false
        }
      })
    })
    this.swirl.getBrandingValues('config').subscribe((values) => {
      this.searchButtonLabel = values.search_button_label ?? this.searchButtonLabel
      this.searchBarPlaceholder = values.search_bar_placeholder ?? this.searchBarPlaceholder
      this.aiResponseLabel = values.ai_response_label ?? this.aiResponseLabel
    });
    this.searchService.isWebsocketLoading.subscribe((isWebsocketLoading) => {
      this.isSearchButtonDisabled = isWebsocketLoading
      if(!isWebsocketLoading)
        this.searchForm.get('checkedGenerateAiResponse')?.patchValue(false)
    })
    this.searchService.isLoading.subscribe((isLoading) => {
      const checkedGenerateAiResponseControl =
        this.searchForm.get('checkedGenerateAiResponse');

      if (isLoading && checkedGenerateAiResponseControl) {
        checkedGenerateAiResponseControl.disable(); // Dynamically disable
      } else if (checkedGenerateAiResponseControl) {
        checkedGenerateAiResponseControl.enable(); // Dynamically enable
      }
    })
  }

  formatPromptName(name: string): string {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  onSourcesSelect(e: any) {
    // 'All sources' is selected on current step
    if(!this.previousSelectedSources.includes('default') && e.value.includes('default'))
      this.selectedSources.setValue(['default'])
    // 'All sources' was selected and additional resource is selected on current step
    else if(this.previousSelectedSources.includes('default') && e.value.includes('default'))
      this.selectedSources.setValue(e.value.filter((s: any) => s !== 'default'))
    else if(e.value.length === 0)
      this.selectedSources.setValue(this.previousSelectedSources)
    else
      this.selectedSources.setValue(e.value)
    this.previousSelectedSources = [...this.selectedSources.value]
  }

  onPromptSelect(e: any) {
    this.selectedPrompt.setValue(e.value)

    const queryParams = { ...this.route.snapshot.queryParams };
    if(queryParams.hasOwnProperty('prompt')) {
      delete queryParams['prompt']
    }
    if(this.selectedPrompt.value != 'default') {
      queryParams['prompt'] = this.selectedPrompt.value
    }
  }

  onSubmit() {
    if(this.searchForm.value?.query && this.searchForm.value?.query?.length > 0) {
      const queryParams: { [key: string]: any } = {
        q: this.searchForm.value.query,
      }
      if(!this.selectedSources.value.includes('default')) {
        queryParams['providers'] = [this.selectedSources.value]
      }
      if(this.searchForm.value.checkedGenerateAiResponse){
        queryParams['rag'] = 'true'
        if(this.selectedPrompt.value != 'default') {
          queryParams['prompt'] = this.selectedPrompt.value
        }
      }
      this.router.navigate(['/'], { queryParams, state: { rerun: true } });
    }
  }

  toggleChange(event: any) {
    this.searchForm.get('checkedGenerateAiResponse')?.patchValue(event.source.checked)
    const queryParams = { ...this.route.snapshot.queryParams };
    if(!event.source.checked) {
      this.webSocketService.send('stop')
      this.searchService.websocketLoading.next(false)
    }

    let providersIds = this.searchService.getRefine().map((r: any) => r['value'])
    let prompt = this.selectedPrompt.value != 'default' ? this.selectedPrompt.value : ''
    this.webSocketService.changeStatus(event.source.checked, false, providersIds, prompt)

    if(event.source.checked){
      queryParams['rag'] = 'true'
      if(prompt) {
        queryParams['prompt'] = prompt
      }
    }else if(queryParams.hasOwnProperty('rag')){
      delete queryParams['rag']
      if(queryParams.hasOwnProperty('prompt')) {
        delete queryParams['prompt']
      }
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: ''
    });
  }
}
