import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { query } from 'express';

@Injectable({
  providedIn: 'root'
})
export class ChatAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.authService.isLoggedIn() ) {
       if (this.authService.getChatStatus()) {
        return true;
       }
       // User or instance not licensed or authorized to use chat
       // Redirect to home page as authenication complete
       this.router.navigate(['/'])
      }

      // User not logged in and we cannot know if they are authorized to use chat
      this.router.navigate(['/login'], { queryParams: { fromComponent: 'chat' } });
      return false;
  }
}